import React, { useState } from "react";
import Sidebar from "./Sidebar";
import STLViewer from "./STLViewer";

const CanvasComponent = () => {
  const [file, setFile] = useState(null);
  const [boundingBox, setBoundingBox] = useState(null);
  const [scale, setScale] = useState(100);
  const [volume, setVolume] = useState(0);

  const handleFileUpload = (uploadedFile) => {
    const url = URL.createObjectURL(uploadedFile);
    setFile(url);
  };

  return (
    <div className="d-flex bg-stone-100 w-screen">
      <Sidebar
        onFileUpload={handleFileUpload}
        boundingBox={boundingBox}
        setScale={setScale}
        volume={volume}
      />
      <div className="w-4/5">
        <STLViewer
          file={file}
          setBoundingBox={setBoundingBox}
          scale={scale}
          setVolume={setVolume}
        />
      </div>
    </div>
  );
};

export default CanvasComponent;
