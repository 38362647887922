import React, { Suspense, useEffect, useState, useRef } from "react";
import { Canvas, useLoader, useFrame, useThree } from "@react-three/fiber";
import { Grid, OrbitControls, Edges, Environment } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { TextureLoader } from "three";
import * as THREE from "three";

const Model = ({ url, setBoundingBox, scale, setVolume }) => {
  const geometry = useLoader(STLLoader, url);
  const matcapTexture = useLoader(TextureLoader, "/matCaps/3.png"); // Replace with your matcap texture path

  const meshRef = useRef();

  useEffect(() => {
    if (meshRef.current) {
      const box = new THREE.Box3().setFromObject(meshRef.current);
      setBoundingBox(box);
      const volume = calculateVolume(geometry);
      setVolume(volume * Math.pow(scale / 100, 3)); // Adjust volume based on scale
    }
  }, [geometry, setBoundingBox, setVolume]);

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.scale.set(scale / 100, scale / 100, scale / 100);
    }
  }, [scale]);

  return (
    <mesh
      ref={meshRef}
      geometry={geometry}
      castShadow
      rotation={[(3 * Math.PI) / 2, 0, 0]}
    >
      <meshMatcapMaterial matcap={matcapTexture} />
    </mesh>
  );
};

function calculateVolume(geometry) {
  let volume = 0;
  const position = geometry.attributes.position;
  const faces = position.count / 3;

  for (let i = 0; i < faces; i++) {
    const p1 = new THREE.Vector3().fromBufferAttribute(position, i * 3);
    const p2 = new THREE.Vector3().fromBufferAttribute(position, i * 3 + 1);
    const p3 = new THREE.Vector3().fromBufferAttribute(position, i * 3 + 2);

    volume += signedVolumeOfTriangle(p1, p2, p3);
  }

  return Math.abs(volume);
}

function signedVolumeOfTriangle(p1, p2, p3) {
  return p1.dot(p2.cross(p3)) / 6.0;
}

const CameraController = ({ boundingBox }) => {
  const { camera, controls } = useThree();

  useFrame(() => {
    if (boundingBox && controls) {
      const center = boundingBox.getCenter(new THREE.Vector3());
      const size = boundingBox.getSize(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      const cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));

      camera.position.set(center.x, center.y, cameraZ);
      camera.lookAt(center);
      controls.target.set(center.x, center.y, center.z);
      controls.update();
    }
  });

  return null;
};

const STLViewer = ({ file, setBoundingBox, scale, setVolume }) => {
  const [url, setUrl] = useState(null);
  const [boundingBox, setLocalBoundingBox] = useState(
    new THREE.Box3(new THREE.Vector3(-1, -1, -1), new THREE.Vector3(1, 1, 1))
  );
  const lightTargetRef = useRef(new THREE.Object3D());

  useEffect(() => {
    if (file) {
      setUrl(file);
    }
  }, [file]);

  useEffect(() => {
    if (boundingBox) {
      const center = boundingBox.getCenter(new THREE.Vector3());
      lightTargetRef.current.position.copy(center);
    }
  }, [boundingBox]);

  return (
    <Canvas
      shadows
      style={{ background: "#ce0d1" }}
      camera={{ position: [0, 10, 30], fov: 60 }}
    >
      <ambientLight intensity={0.7} />
      <spotLight
        position={[30, 30, 10]}
        angle={0.3}
        penumbra={1}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />

      <Grid
        infiniteGrid
        cellColor="black"
        cellSize={1}
        sectionSize={10}
        sectionColor="black"
        sectionThickness={1}
        cellThickness={0.6}
        followCamera={false}
        position={[0, 0, 0]}
        // Rotate the grid to align with the XY plane
      />
      {url && (
        <Suspense fallback={<Loading />}>
          <Model
            url={url}
            scale={scale}
            setBoundingBox={(box) => {
              setBoundingBox(box);
              setLocalBoundingBox(box);
            }}
            setVolume={setVolume}
          />
        </Suspense>
      )}
      <CameraController boundingBox={boundingBox} />
      <OrbitControls enableRotate={true} enablePan={true} />
      <Environment
        files="https://res.cloudinary.com/dtmnmiu76/raw/upload/v1733423128/belfast_sunset_puresky_1k_pnklgx.hdr"
        background
        backgroundBlurriness={0}
      />
    </Canvas>
  );
};

const Loading = () => {
  return (
    <mesh>
      <boxGeometry />
      <meshStandardMaterial color="orange" />
    </mesh>
  );
};

export default STLViewer;
