import React, { Suspense } from "react";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import * as THREE from "three";

const Model = ({ url, scroll }) => {
  const geometry = useLoader(STLLoader, url);
  const matcapTexture = useLoader(
    THREE.TextureLoader,
    "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424481/1_nkbalr.png"
  );
  const opacityMain = (1 / scroll.maxDepth) * scroll.top;
  const opacityEdge = 1 - (1 / scroll.maxDepth) * scroll.top;

  // Create vertices geometry
  const vertices = geometry.attributes.position.array;
  const verticesGeometry = new THREE.BufferGeometry();
  verticesGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  return (
    <group>
      <mesh geometry={geometry} scale={7} rotation={[(3 * Math.PI) / 2, 0, 0]}>
        <meshMatcapMaterial
          matcap={matcapTexture}
          transparent
          opacity={opacityMain}
        />
      </mesh>
      {opacityEdge < 1 && (
        <mesh
          geometry={geometry}
          scale={7}
          rotation={[(3 * Math.PI) / 2, 0, 0]}
        >
          <meshStandardMaterial
            // emissive={"#FDF4DC"}
            wireframe
            color="#FFFDF8"
            transparent
            opacity={opacityEdge}
          />
        </mesh>
      )}
      {opacityEdge > 0.9 && (
        <points
          geometry={verticesGeometry}
          scale={7}
          rotation={[(3 * Math.PI) / 2, 0, 0]}
        >
          <pointsMaterial color={"#191816"} size={0.01} transparent />
        </points>
      )}
    </group>
  );
};

const RotatingCamera = ({ target, scroll }) => {
  const { camera } = useThree();

  const zoomFactor = 5 + (scroll.top * 25) / scroll.maxDepth;
  const angle = (scroll.top * 2 * Math.PI) / scroll.maxDepth;
  // Adjust the multiplier to control the rotation speed
  camera.position.x = zoomFactor * Math.sin(angle);
  camera.position.z = zoomFactor * Math.cos(angle);
  camera.position.y = -3;

  useFrame(() => {
    camera.lookAt(target);
  });

  return null;
};

const BackgroundCanvas = ({ scroll }) => {
  const target = new THREE.Vector3(0, -3, 0);
  return (
    <Canvas
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
      }}
    >
      <ambientLight intensity={0} />
      <Suspense fallback={null}>
        <Model
          url="https://res.cloudinary.com/dtmnmiu76/raw/upload/v1733424420/Hippocrates2_msmej3.stl"
          scroll={scroll}
        />
      </Suspense>
      <Environment
        files="https://res.cloudinary.com/dtmnmiu76/raw/upload/v1733424346/moonlit_golf_1k_ptpqqi.hdr"
        background
        backgroundBlurriness={0.6}
      />
      <RotatingCamera target={target} scroll={scroll} fov={60} />
      <OrbitControls />
    </Canvas>
  );
};

export default BackgroundCanvas;
