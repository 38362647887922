import React from "react";
import CanvasComponent from "../Components/Canvas";

const PrintingService = () => {
  return (
    <section>
      <CanvasComponent />
    </section>
  );
};

export default PrintingService;
