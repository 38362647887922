import * as THREE from "three";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Canvas, extend, useFrame } from "@react-three/fiber";
import {
  Image,
  ScrollControls,
  useScroll,
  Billboard,
  Text,
} from "@react-three/drei";
import { suspend } from "suspend-react";
import { easing, geometry } from "maath";

extend(geometry);
const inter = import("@pmndrs/assets/fonts/inter_regular.woff");

const images = [
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424621/img1_edo6pw.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424615/img10_igz3hu.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424627/img3_dqry2c.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424633/img4_efqh2k.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424632/img5_zbwksp.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424618/img6_etbo2c.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424614/img2_a7nyw8.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424625/img8_bzb6r6.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424613/img9_ghjpxv.jpg",
  "https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424633/img4_efqh2k.jpg",
];

const WebHero = () => {
  return (
    <Canvas
      dpr={[1, 1.5]}
      style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <ScrollControls pages={4} infinite>
        <Scene position={[0, 1.5, 0]} />
      </ScrollControls>
    </Canvas>
  );
};

function Scene({ children, ...props }) {
  const ref = useRef();
  const scroll = useScroll();
  const [hovered, hover] = useState(null);
  useFrame((state, delta) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2); // Rotate contents
    state.events.update(); // Raycasts every frame rather than on pointer-move
    easing.damp3(
      state.camera.position,
      [-state.pointer.x * 2, state.pointer.y * 2 + 4.5, 9],
      0.3,
      delta
    );
    state.camera.lookAt(0, 0, 0);
  });
  return (
    <group ref={ref} {...props}>
      <Cards
        category="Branding"
        from={0}
        len={Math.PI / 4}
        onPointerOver={hover}
        onPointerOut={hover}
      />
      <Cards
        category="E-Commerse"
        from={Math.PI / 4}
        len={Math.PI / 2}
        position={[0, 0.4, 0]}
        onPointerOver={hover}
        onPointerOut={hover}
      />
      <Cards
        category="Portfolio"
        from={Math.PI / 4 + Math.PI / 2}
        len={Math.PI / 2}
        onPointerOver={hover}
        onPointerOut={hover}
      />
      <Cards
        category="Marketing"
        from={Math.PI * 1.25}
        len={Math.PI * 2 - Math.PI * 1.25}
        position={[0, -0.4, 0]}
        onPointerOver={hover}
        onPointerOut={hover}
      />
      <ActiveCard hovered={hovered} />
    </group>
  );
}

function Cards({
  category,
  data,
  from = 0,
  len = Math.PI * 2,
  radius = 5.25,
  onPointerOver,
  onPointerOut,
  ...props
}) {
  const [hovered, hover] = useState(null);
  const amount = Math.round(len * 22);
  const textPosition = from + (amount / 2 / amount) * len;
  return (
    <group {...props}>
      <Billboard
        position={[
          Math.sin(textPosition) * radius * 1.4,
          0.5,
          Math.cos(textPosition) * radius * 1.4,
        ]}
      >
        <Text
          font={suspend(inter).default}
          fontSize={0.25}
          anchorX="center"
          color="black"
        >
          {category}
        </Text>
      </Billboard>
      {Array.from(
        { length: amount - 3 /* minus 3 images at the end, creates a gap */ },
        (_, i) => {
          const angle = from + (i / amount) * len;
          return (
            <Card
              key={angle}
              onPointerOver={(e) => (
                e.stopPropagation(), hover(i), onPointerOver(i)
              )}
              onPointerOut={() => (hover(null), onPointerOut(null))}
              position={[Math.sin(angle) * radius, 0, Math.cos(angle) * radius]}
              rotation={[0, Math.PI / 2 + angle, 0]}
              active={hovered !== null}
              hovered={hovered === i}
              url={images[i % 10]}
            />
          );
        }
      )}
    </group>
  );
}

function Card({ url, active, hovered, ...props }) {
  const ref = useRef();
  useFrame((state, delta) => {
    const f = hovered ? 1.4 : active ? 1.25 : 1;
    easing.damp3(ref.current.position, [0, hovered ? 0.25 : 0, 0], 0.1, delta);
    easing.damp3(ref.current.scale, [1.618 * f, 1 * f, 1], 0.15, delta);
  });
  return (
    <group {...props}>
      <Image
        ref={ref}
        transparent
        radius={0.075}
        url={url}
        scale={[1.618, 1, 1]}
        side={THREE.DoubleSide}
      />
    </group>
  );
}

function ActiveCard({ hovered, ...props }) {
  const names = [
    "Tourism",
    "Educational Institutions",
    "E-Commerse",
    "Personal Portfolios",
    "Real Estate & Hotels",
    "Restaurants and Cafes",
    "Marketing",
    "Artists and Designers",
    "Branding",
    "Photography Studios",
  ];

  const ref = useRef();
  const name = useMemo(() => names[hovered % 10]);
  useLayoutEffect(() => void (ref.current.material.zoom = 0.8), [hovered]);
  useFrame((state, delta) => {
    easing.damp(ref.current.material, "zoom", 1, 0.5, delta);
    easing.damp(ref.current.material, "opacity", hovered !== null, 0.3, delta);
  });
  return (
    <Billboard {...props}>
      <Text
        font={suspend(inter).default}
        fontSize={0.5}
        position={[2.15, 3.85, 0]}
        anchorX="left"
        color="black"
      >
        {hovered !== null && `${name}\n${hovered}`}
      </Text>
      <Image
        ref={ref}
        transparent
        radius={0.3}
        position={[0, 1.5, 0]}
        scale={[3.5, 1.618 * 3.5, 0.2, 1]}
        url={images[hovered % 10]}
      />
    </Billboard>
  );
}
export default WebHero;
