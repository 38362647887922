import axios from "axios";
import React, { useState, useEffect } from "react";
import * as THREE from "three";
import Modal from "react-modal";

Modal.setAppElement("#root");

const Sidebar = ({ onFileUpload, boundingBox, setScale, volume }) => {
  const [size, setSize] = useState({ x: 0, y: 0, z: 0 });
  const [scale, setScaleValue] = useState(100);
  const [printingType, setPrintingType] = useState("FDM");
  const [printLayerHeight, setPrintLayerHeight] = useState(0.2);
  const [printMaterial, setPrintMaterial] = useState("PLA");
  const [infillPercentage, setInfillPercentage] = useState(20);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [file, setFile] = useState(null);
  const [modelExist, setModelExist] = useState(() => {
    return false;
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(() => {
    return {
      name: "",
      address: "",
      phoneNumber: "",
      state: "",
      pincode: "",
      email: "",
    };
  });
  const [errors, setErrors] = useState({});
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (boundingBox) {
      const newSize = boundingBox.getSize(new THREE.Vector3());
      setSize({ x: newSize.x, y: newSize.y, z: newSize.z });
    }
  }, [boundingBox]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && onFileUpload) {
      onFileUpload(file);
      setFile(file);
      setEstimatedCost(0);
      setModelExist(false);
    }
  };
  const handleScaleChange = (event) => {
    const newScale = parseFloat(event.target.value);
    setScaleValue(newScale);
    setScale(newScale);
  };
  const handlePrintingTypeChange = (event) => {
    setPrintingType(event.target.value);
    // Reset print material when printing type changes
    setPrintMaterial(event.target.value === "SLA" ? "Normal Resin" : "PLA");
    // Reset print layer height when printing type changes
    setPrintLayerHeight(event.target.value === "SLA" ? 0.025 : 0.2);
  };

  const handlePrintLayerHeightChange = (event) => {
    let newLayerHeight = parseFloat(event.target.value);
    const minLayerHeight = printingType === "SLA" ? 0.025 : 0.2;
    const maxLayerHeight = printingType === "SLA" ? 0.1 : 0.7;
    if (
      isNaN(newLayerHeight) ||
      newLayerHeight < minLayerHeight ||
      newLayerHeight > maxLayerHeight
    ) {
      newLayerHeight = minLayerHeight;
    }
    setPrintLayerHeight(newLayerHeight);
  };

  const handlePrintMaterialChange = (event) => {
    setPrintMaterial(event.target.value);
  };

  const raiseRequest = async () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", customerDetails.name);
      formData.append("address", customerDetails.address);
      formData.append("phoneNumber", customerDetails.phoneNumber);
      formData.append("email", customerDetails.email);
      formData.append("size", JSON.stringify(size));
      formData.append("scale", scale);
      formData.append("printingType", printingType);
      formData.append("printMaterial", printMaterial);
      formData.append("printLayerHeight", printLayerHeight);
      formData.append("infillPercentage", infillPercentage);
      formData.append("estimatedCost", estimatedCost);
      try {
        const response = await axios.post(
          "http://localhost:5000/raisePrintingReq",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        console.log("Request submitted successfully:", response.data);
        closeModal();
      } catch (error) {
        console.error("Error while raising req for printing service", error);
      }
    }
  };

  useEffect(() => {
    if (modelExist) {
      calculateEstimatedCost();
    }
  }, [
    size,
    scale,
    printingType,
    printMaterial,
    printLayerHeight,
    modelExist,
    volume,
  ]);

  const calculateEstimatedCost = () => {
    // Example cost calculation logic
    const actualVolume = volume * (1.2 + infillPercentage / 100);
    const printTime =
      ((size.z * 10) / printLayerHeight) * size.x * size.y * 0.03;

    const materialCost = printMaterial === "PLA" ? 1.24 : 0.1; // Example cost per cubic unit
    const layerHeightMultiplier = 10 / printLayerHeight; // Example multiplier
    const cost = actualVolume * materialCost + printTime * 1.2; // Example multiplier
    setEstimatedCost(cost.toFixed(2));
    if (volume > 0) {
      setModelExist(true);
    }
  };
  const printMaterialOptions =
    printingType === "SLA"
      ? ["Normal Resin", "Flexible Resin", "ABS Like Resin"]
      : ["PLA", "ABS", "PETG", "TPU"];

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleCustomerDetailsChange = (event) => {
    const { name, value } = event.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const validateForm = () => {
    const newErrors = {};
    if (!customerDetails.name) newErrors.name = "Name is required";
    if (!customerDetails.address) newErrors.address = "Address is required";
    if (!customerDetails.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(customerDetails.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be 10 digits";
    }
    if (!customerDetails.state) newErrors.state = "Address is required";
    if (!customerDetails.pincode) {
      newErrors.pincode = "Address is required";
    } else if (!/^\d{6}$/.test(customerDetails.pincode)) {
      newErrors.pincode = "Pincode mentioned is wrong";
    }

    if (!customerDetails.email) {
      newErrors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(customerDetails.email)) {
      newErrors.email = "Email address is invalid";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleConfirm = () => {
    if (validateForm()) {
      raiseRequest();
    }
  };
  return (
    <div
      className="d-flex flex-column bg-none w-1/5"
      style={{ height: "100vh" }}
    >
      <div className="mt-0  bg-violet-100 px-3 py-2">
        <h3>Model Viewer</h3>
      </div>

      <div className="p-3">
        <label class="block">
          <span class="sr-only">upload STL File</span>
          <input
            type="file"
            accept=".stl"
            onChange={handleFileChange}
            class="block w-full text-lg text-slate-500
      file:mr-4 file:py-2 file:px-4 file:py-2
      file:rounded-full file:border-1
      file:text-lg file:font-bold
      file:bg-violet-100 file:text-violet-900
      hover:file:bg-violet-200
    "
          />
        </label>

        <div className="mt-3">
          <label>Width (X): {size.x.toFixed(2)} cm</label>
        </div>
        <div className="mt-3">
          <label>Length (Y): {size.y.toFixed(2)} cm</label>
        </div>
        <div className="mt-3">
          <label>Depth (Z): {size.z.toFixed(2)} cm</label>
        </div>
        <div className="mt-3">
          <label>Volume: {volume.toFixed(2)} cm3</label>
        </div>
        <div className="mt-3">
          <label>Scale (%):</label>
          <input
            type="number"
            value={scale}
            onChange={handleScaleChange}
            className="form-control"
            step="1"
            min="10"
          />
        </div>
        <div className="mt-3">
          <label>Printing Type:</label>
          <select
            value={printingType}
            onChange={handlePrintingTypeChange}
            className="form-control"
          >
            <option value="FDM">FDM</option>
            <option value="SLA">SLA</option>
          </select>
        </div>
        <div className="mt-3">
          <label>Print Material:</label>
          <select
            value={printMaterial}
            onChange={handlePrintMaterialChange}
            className="form-control"
          >
            {printMaterialOptions.map((material) => (
              <option key={material} value={material}>
                {material}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-3">
          <label>Print Layer Height (mm):</label>
          <input
            type="number"
            value={printLayerHeight}
            onChange={handlePrintLayerHeightChange}
            className="form-control"
            step={printingType === "SLA" ? 0.005 : 0.05}
            min={printingType === "SLA" ? 0.025 : 0.2}
            max={printingType === "SLA" ? 0.1 : 0.7}
          />
        </div>
        <div className="mt-3">
          <label>Infill Percentage:</label>
          <input
            type="number"
            value={infillPercentage}
            onChange={(event) => setInfillPercentage(event.target.value)}
            className="form-control"
            step="5"
            min="5"
            max="100"
          />
        </div>
        <div className="mt-3">
          <label class="block">
            <button
              onClick={calculateEstimatedCost}
              class="block w-full text-lg text-slate-500
      mr-4 py-2 px-4py-2
      rounded-full border-1
      text-lg font-bold
      bg-violet-100 text-violet-900
      hover:bg-violet-200"
            >
              Calculate Estimated Cost
            </button>
          </label>
        </div>
        {estimatedCost !== null && (
          <div className="mt-3">
            <label>Estimated Cost: {`₹${estimatedCost}`}</label>
          </div>
        )}
        {modelExist && (
          <div className="mt-3">
            <button
              onClick={openModal}
              class="block w-full text-lg text-slate-500
  mr-4 py-2 px-4py-2
  rounded-full border-1
  text-lg font-bold
  bg-violet-100 text-violet-900
  hover:bg-violet-200"
            >
              Proceed to Print
            </button>
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Customer Details"
          className="fixed inset-0 z-10 w-screen flex items-center justify-center"
        >
          <div className="transform overflow-auto rounded-lg text-left shadow-xl transition-all w-2/5">
            {!confirmed && (
              <div className="bg-white px-4 pb-4 pt-5 ">
                <h2 className="text-2xl font-bold mb-4 text-center">
                  Please fill in your Details
                </h2>
                <form>
                  <div className="mb-4">
                    <label className="block text-md font-semiBold mb-2">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={customerDetails.name}
                      onChange={handleCustomerDetailsChange}
                      className="shadow border rounded w-full py-2 px-3 h-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {errors.name && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.name}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-md font-semiBold mb-2">
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={customerDetails.address}
                      onChange={handleCustomerDetailsChange}
                      className="shadow border rounded w-full py-2 px-3 h-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {errors.address && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.address}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-md font-semiBold mb-2">
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={customerDetails.state}
                      onChange={handleCustomerDetailsChange}
                      className="shadow border rounded w-full py-2 px-3 h-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {errors.state && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.state}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-md font-semiBold mb-2">
                      Pincode
                    </label>
                    <input
                      type="text"
                      name="pincode"
                      value={customerDetails.pincode}
                      onChange={handleCustomerDetailsChange}
                      className="shadow border rounded w-full py-2 px-3 h-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {errors.pincode && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.pincode}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-md font-semiBold mb-2">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={customerDetails.phoneNumber}
                      onChange={handleCustomerDetailsChange}
                      className="shadow border rounded w-full py-2 px-3 h-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-md font-semiBold mb-2">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={customerDetails.email}
                      onChange={handleCustomerDetailsChange}
                      className="shadow border rounded w-full py-2 px-3 h-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs italic mt-2">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={handleConfirm}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Confirm
                  </button>
                </form>
              </div>
            )}
            {confirmed && (
              <div className="bg-white px-4 pb-4 pt-5 ">
                <h3>Thank you</h3>
                <div className="">
                  We will connect with you shortly to discuss the requirement
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Sidebar;
