import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import PrintingService from "./Pages/PrintingService";
import WebDesign from "./Pages/WebDesign";
import Header from "./Components/Header";

function App() {
  return (
    <Router>
      <div className="container-fluid p-0">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/3dpservice" element={<PrintingService />} />
          <Route path="/webDesign" element={<WebDesign />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
