import React, { useEffect } from "react";
import WebHero from "../Components/WebHero";
import "./WebDesign.css"; // Import the CSS file for custom styles

const WebDesign = () => {
  const handleScroll = () => {
    const scrollPosition = document.getElementById("webWrapper").scrollTop;
    const scrollHeight = document.getElementById("webWrapper").scrollHeight;
    console.log("position", scrollPosition, scrollHeight);
    const webWrapper = document.getElementById("webWrapper");
    if (webWrapper) {
      webWrapper.style.backgroundPositionY = `${-scrollPosition * 1.7}px`;
    }
  };

  return (
    <div
      className="overflow-auto wiggling-background webWrapper"
      id="webWrapper"
      onScroll={handleScroll}
    >
      {/* Highlight */}
      <section className="text-white h-4/5">
        <WebHero />
      </section>
      {/* Hero Section */}
      <section className="text-white py-10 h-screen">
        <div className="bg-sky-900 bg-opacity-70  center p-20 w-full text-center">
          <h1 className="text-7xl font-bold mb-4">Crafting your Dreams</h1>
          <p className="text-3xl">We bring your web design ideas to life</p>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 h-screen">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-sky-950 bg-opacity-95 text-cyan-50 shadow-lg rounded-lg p-3 text-center">
              <h3 className="text-2xl font-bold mb-4">Responsive Design</h3>
              <p>
                We create responsive websites that look great on all devices.
              </p>
              <img
                src="/images/hero/img1.jpg"
                alt="Responsive Design"
                className="w-full h-96 object-cover rounded-t-lg mt-4"
              />
            </div>
            <div className="bg-sky-950 bg-opacity-95 text-cyan-50 shadow-lg rounded-lg p-3 text-center">
              <h3 className="text-2xl font-bold mb-4">E-commerce Solutions</h3>
              <p>
                Our e-commerce solutions help you sell your products online.
              </p>
              <img
                src="/images/hero/img3.jpg"
                alt="Responsive Design"
                className="w-full h-96 object-cover rounded-t-lg mt-4"
              />
            </div>
            <div className="bg-sky-950 bg-opacity-95 text-cyan-50 shadow-lg rounded-lg p-3 text-center">
              <h3 className="text-2xl font-bold mb-4">SEO Optimization</h3>
              <p>
                We optimize your website to rank higher in search engine
                results.
              </p>
              <img
                src="/images/hero/img10.jpg"
                alt="Responsive Design"
                className="w-full h-96 object-cover rounded-t-lg mt-4"
              />
            </div>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className=" py-20 h-4/5">
        <div className="bg-gray-100 bg-opacity-80 center p-20 w-full text-center">
          <h2 className="text-4xl font-bold mb-4">About us</h2>
          <p className="text-xl">
            We are a team of passionate web designers and developers dedicated
            to creating beautiful and functional websites. Our mission is to
            help businesses establish a strong online presence and achieve their
            goals through our interactive 3 dimensional approach to web design.
          </p>
          <a
            href="mailto:admin@ohrdesigns.com"
            className="mt-6 inline-block bg-sky-900 text-white font-semibold py-2 px-4 rounded"
          >
            Write to Us
          </a>
        </div>
      </section>
    </div>
  );
};

export default WebDesign;
